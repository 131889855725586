import { Project } from '@nexploretechnology/concreting-core-client/concrete/project.management-client/project.management.dto';
import { DataList, Direction, OffsetPagination } from '@nexploretechnology/concreting-core-client/pagination';
import { Table, TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult } from 'antd/lib/table/interface';
import { useState } from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import ProjectModalForm from 'src/app-react/business-page/project/components/ProjectModalForm/ProjectModalForm';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import { BUSINESS_PAGES } from 'src/app-react/constants/businessPages';
import useApi from 'src/app-react/hooks/useApi';
import useGlobalState from 'src/app-react/hooks/useGlobalState';
import getProjectBusinessPages from 'src/app-react/services/projectService/getProjectBusinessPages';
import getProjectPermissions from '../../utils/getProjectPermissions';
import ManageUsersForm from '../ManageUsersForm/ManageUsersForm';
import SupplierConfigForm from '../SupplierConfigForm/SupplierConfigForm';
import { projectsTableColumns } from './ProjectsTable.columns';
import SuccessNotification from 'src/app-react/components/Notification/SuccessNotification';
import useProjectPageState from 'src/app-react/hooks/useProjectPageState';

const tabNames = ['Active', 'Archived'] as const;
type TabName = (typeof tabNames)[number];

interface ProjectsTableProps {
    tab: TabName;
    projects: DataList<Project>;
    setPagination: React.Dispatch<OffsetPagination<Project>>;
}

function ProjectsTable({ tab, projects, setPagination }: ProjectsTableProps) {
    const [projectId, setProjectId] = useState<string>('');
    const [showEditModal, setShowEditModal] = useState<boolean>(false);
    const [showSupplierModal, setShowSupplierModal] = useState<boolean>(false);
    const [showManageModal, setShowManageModal] = useState<boolean>(false);
    const navigate: NavigateFunction = useNavigate();
    const { userProfile } = useGlobalState();
    const { reloadProjectPage, companyId } = useProjectPageState();

    const userCompany = userProfile?.companies?.find((c) => c.companyId === companyId);
    const api = useApi();

    const paginationConfig = {
        position: ['bottomRight'],
        defaultPageSize: 10,
        total: projects.count,
        showSizeChanger: true
    } as TablePaginationConfig;

    let sorting: Partial<Record<keyof Project, Direction>>;

    const onChange = async (
        paginationOnChange: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<Project> | SorterResult<Project>[]
    ) => {
        const _sorter = sorter as SorterResult<Project>
        if (_sorter.columnKey && _sorter.order) {
            const sortDirection: string = { ascend: 'asc', descend: 'desc' }[_sorter.order];
            const sortingValue = `{"${_sorter.columnKey}": "${sortDirection || 'DESC'}"}`;
            sorting = JSON.parse(sortingValue);
        }

        const queryParams: OffsetPagination<Project> = {
            skip:
                paginationOnChange.current! === 0
                    ? 0
                    : (paginationOnChange.current! - 1) * paginationOnChange.pageSize!,
            limit: paginationOnChange?.pageSize!,
            sorting: sorting ? [sorting] : []
        };

        setPagination(queryParams);
    }
    const hideModal = () => {
        setShowEditModal(false);
        setShowSupplierModal(false);
        setShowManageModal(false);
    };

    const onEnterProject = (_projectId: string, marketId?: string): void => {
        const businessPages = getProjectBusinessPages({ marketId });
        const { isForeman, isProjectManager, isConcreteEngineer } = getProjectPermissions(userCompany, _projectId);

        if (
            (isProjectManager && !isForeman && !isConcreteEngineer) ||
            (isConcreteEngineer && !isProjectManager && !isForeman)
        ) {
            navigate(`/projects/${_projectId}/catalogue-management`);
        } else if (businessPages.includes(BUSINESS_PAGES.ORDERS)) {
            navigate(`/projects/${_projectId}/orders`);
        } else if (businessPages.includes(BUSINESS_PAGES.DELIVERIES)) {
            navigate(`/projects/${_projectId}/deliveries`);
        }
    };

    const onEditProject = (_projectId: string): void => {
        setProjectId(_projectId);
        setShowEditModal(true);
    };

    const onEditSupplier = (_projectId: string): void => {
        setProjectId(_projectId);
        setShowSupplierModal(true);
    };

    const onManageUsers = (_projectId: string): void => {
        setProjectId(_projectId);
        setShowManageModal(true);
    };
    const onFinishCreateEditProjectModal = (): void => {
        reloadProjectPage();
        hideModal();
    };

    const onArchiveProject = (project: Project): void => {
        api.projectManagementClient
            .archiveProject(companyId, project.id, { isArchived: !project.isArchived })
            .then((response) => {
                if (response.isSuccess()) {
                    SuccessNotification({
                        message: response.getEntity().isArchived
                            ? 'Project has been archived successfully'
                            : 'Project has been activated successfully',
                        description: ''
                    });
                    reloadProjectPage();
                } else {
                    ErrorNotification({ message: 'Error', description: '' });
                }
            })
            .catch((e) => ErrorNotification({ message: e.code, description: e.message }));
    };
    return (
        <>
            <Table
                data-testid="projects-table"
                rowKey="id"
                columns={projectsTableColumns({
                    userCompany,
                    onEditProject,
                    onEnterProject,
                    onEditSupplier,
                    onManageUsers,
                    onArchiveProject
                })}
                dataSource={projects.data}
                showSorterTooltip={false}
                onChange={onChange}
                pagination={paginationConfig}
            />
            <ManageUsersForm
                companyId={companyId}
                projectId={projectId}
                showModal={showManageModal}
                hideModal={hideModal}
                marketId={projects.data?.find((x) => x.id === projectId)?.marketId}
            />
            <ProjectModalForm
                companyId={companyId}
                isEditMode
                projectId={projectId}
                hideModal={hideModal}
                showModal={showEditModal}
                onComplete={onFinishCreateEditProjectModal}
            />
            <SupplierConfigForm
                companyId={companyId}
                projectId={projectId}
                showModal={showSupplierModal}
                hideModal={hideModal}
            />
        </>
    );
}

export default ProjectsTable;
