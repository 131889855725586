import { MinusSquareOutlined, PlusSquareOutlined } from "@ant-design/icons";
import { Trans, t } from "@lingui/macro";
import { Button, Col, Form, Input, Row } from "antd";
import MaxAggregateSizeFormItem from "src/app-react/business-page/shared/FormItems/Catalogue/DE/MaxAggregateSizeFormItem";
import InputFormItem from "src/app-react/components/Form/FormItem/InputFormItem";
import numberRule from "src/app-react/components/Form/Validations/numberRule";
import { MARKETS } from "src/app-react/constants/markets";
import { getProjectConstants } from "src/app-react/services/projectService";

export function AggregatesFormItem(): JSX.Element {
    const {units} = getProjectConstants({marketId: MARKETS.DE});

    return (
        <Form.List name="aggregates">
            {(fields, { add, remove }) => (
                <>
                    {fields.map((field) => (
                        <div key={field.key}>
                            <Row>
                                <Col flex="auto">
                                    <div style={field.name === 0 ? {} : { visibility: 'hidden' }}>
                                        <label className="concrete-type-modal__form-list-title">
                                            <Trans>Aggregate(s)</Trans>
                                        </label>
                                    </div>
                                </Col>
                                <Col flex="none">
                                    <Form.Item name={[field.name, 'remove']}>
                                        <Button
                                            className="concrete-type-modal__form-list-remove-button"
                                            type="link"
                                            size="small"
                                            onClick={() => {
                                                remove(field.name);
                                            }}>
                                            <MinusSquareOutlined />
                                            <Trans>Remove</Trans>
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Row gutter={12}>
                                        <Col span={4}>
                                            <InputFormItem
                                                required
                                                name={[field.name, 'type']}
                                                label={t`Type`}
                                            >
                                                <Input data-testid="type" placeholder={t`Enter type`} />
                                            </InputFormItem>
                                        </Col>
                                        <Col span={4}>
                                            <InputFormItem
                                                required={false}
                                                name={[field.name, 'origin']}
                                                label={t`Origin`}>
                                                <Input data-testid="origin" placeholder={t`Enter origin`} />
                                            </InputFormItem>
                                        </Col>

                                        <Col span={4}>
                                            <InputFormItem
                                                required={false}
                                                name={[field.name, 'quantity']}
                                                label={t`Quantity`}
                                                rules={[
                                                    {
                                                        pattern: new RegExp(/^-?\d+(?:\.\d{1,2})?$/),
                                                        message: t`Please enter the quantity`
                                                    }
                                                ]}>
                                                <Input
                                                    type="number"
                                                    data-testid="quantity"
                                                    placeholder={t`Enter quantity`}
                                                    addonAfter={units.density}
                                                />
                                            </InputFormItem>
                                        </Col>
                                        <Col span={5}>
                                            <Form.Item
                                                name={[field.name, 'minAggregateSize']}
                                                label={t`Min/max aggregate size`}
                                                rules={[numberRule({ min: 0, message: t`Please enter the minimum aggregate size` })]}>
                                                <Input type="number" data-testid="minAggregateSize" placeholder={t`Enter the minimum aggregate size`} />
                                            </Form.Item>
                                        </Col>
                                        <Col span="1">
                                            <span
                                                style={{
                                                    display: 'inline-block',
                                                    width: '100%',
                                                    alignContent: 'center',
                                                    margin: '2.5rem 0.5rem'
                                                }}>
                                                /
                                            </span>
                                        </Col>

                                        <Col span={6}>
                                            <MaxAggregateSizeFormItem
                                                name={[field.name, 'maxAggregateSize']}
                                                label={null}
                                                style={{ marginTop: '34px' }}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col flex="auto" />
                            </Row>
                        </div>
                    ))}

                    <Form.Item>
                        <Button
                            data-testid="aggregates"
                            className="concrete-type-modal__form-list-add-button"
                            type="link"
                            size="small"
                            onClick={() => {
                                add({});
                            }}>
                            <PlusSquareOutlined /> <Trans>Add aggregate</Trans>
                        </Button>
                    </Form.Item>
                </>
            )}
        </Form.List>
    );
}
