import { DeleteOutlined, DownloadOutlined, FileOutlined, UploadOutlined } from '@ant-design/icons';
import { i18n } from '@lingui/core';
import { Trans, t } from '@lingui/macro';
import { SupplierProducedConcreteParamsAUS } from '@nexploretechnology/concreting-core-client/concrete/concreting.documentation-client/concreting.documentation.dto';
import { Button, Tag } from 'antd';
import moment from 'moment';
import { DeliveryNoteAllDetails } from 'src/app-react/business-page/delivery-details/hooks/useDeliveryNoteDetails';
import { NumberFormatter } from 'src/app-react/components/Formatters';
import { IMarketUnits } from 'src/app-react/constants/marketsData';
import { UserPermissions } from 'src/app-react/hooks/useUserPermissions';

const DEFAULT_FORMAT = 'DD/MM/YYYY HH:mm';

export const deliveryDetailsTableColumnsAUS = ({
    units,
    isQr,
    dateTimeFormat,
    setUploadDeliveryNoteModalVisible,
    deleteFileClicked,
    downloadAttachmentClicked,
    permissions
}: {
    units: IMarketUnits;
    isQr: boolean;
    dateTimeFormat?: string;
    setUploadDeliveryNoteModalVisible?: React.Dispatch<React.SetStateAction<boolean>>;
    deleteFileClicked?: (d: DeliveryNoteAllDetails) => void;
    downloadAttachmentClicked?: (d: DeliveryNoteAllDetails) => void;
    permissions: UserPermissions;
}) => {
    const columns = [
        {
            title: <Trans>Delivery docket number</Trans>,
            key: 'deliveryNoteNo',
            dataIndex: 'deliveryNoteNo',
            width: 230,
            render: (val: string, record: DeliveryNoteAllDetails) => (
                <p>{record.supplierConcreteLoadData?.deliveryNoteNumber}</p>
            )
        },

        {
            title: <Trans>License plate</Trans>,
            key: 'licensePlate',
            dataIndex: 'licensePlate',
            width: 150,
            render: (val: string, record: DeliveryNoteAllDetails) => (
                <p>{record.supplierConcreteLoadData?.truckNumber}</p>
            )
        }
    ];

    if (isQr) {
        columns.push({
            title: <Trans>QR Scan</Trans>,
            key: 'qr-scan',
            dataIndex: 'qr-scan',
            width: 150,
            render: (val: string, record: DeliveryNoteAllDetails) => (
                <>
                    <p>
                        {record.supplierConcreteLoad?.type === 'QR' ||
                        /qr/i.test(record?.supplierConcreteLoadData?.origin) ? (
                            <Tag color="green">{t`Yes`}</Tag>
                        ) : (
                            <Tag color="grey">{t`No`}</Tag>
                        )}
                    </p>
                    {record?.concreteLoad?.createdByUserName && record?.concreteLoad?.createdOn && (
                        <p>
                            <Trans>Created by</Trans> {record.concreteLoad.createdByUserName} <Trans>on</Trans>
                            &nbsp;
                            {moment(record.concreteLoad.createdOn)
                                .locale(i18n.locale)
                                .format(dateTimeFormat ?? DEFAULT_FORMAT)}
                        </p>
                    )}
                </>
            )
        });
    }

    columns.push(
        {
            title: <Trans>Product code</Trans>,
            key: 'productCode',
            dataIndex: 'productCode',
            width: 150,
            render: (val: string, record: DeliveryNoteAllDetails) => (
                <p>{(record.supplierProducedConcrete as SupplierProducedConcreteParamsAUS)?.description}</p>
            )
        },

        {
            title: <Trans>Cost code</Trans>,
            key: 'costCode',
            dataIndex: 'costCode',
            width: 150,
            render: (val: string, record: DeliveryNoteAllDetails) => <p>{record.concreteLoad?.externalCustomProp}</p>
        },

        {
            title: <Trans>Truck quantity</Trans>,
            key: 'costCode',
            dataIndex: 'costCode',
            width: 150,
            render: (val: string, record: DeliveryNoteAllDetails) => (
                <p>
                    <NumberFormatter value={record.supplierProducedConcrete?.volume} unit={units.volume} />
                </p>
            )
        },
        {
            title: <Trans>Delivery document</Trans>,
            key: 'deliveryDocument',
            dataIndex: 'deliveryDocument',
            width: 150,
            render: (val: string, record: DeliveryNoteAllDetails) => {
                if (record.attachmentBasicInfo) {
                    return (
                        <>
                            <FileOutlined /> {record.attachmentBasicInfo.filename}
                            <Button
                                type="link"
                                onClick={() => downloadAttachmentClicked(record)}
                                icon={<DownloadOutlined />}
                            />
                            <Button type="link" onClick={() => deleteFileClicked(record)} icon={<DeleteOutlined />} />
                        </>
                    );
                }

                if (permissions.isAllowToUploadDeliveryDocument) {
                    return (
                        <Button
                            data-testid="upload-file"
                            type="link"
                            size="small"
                            onClick={() => setUploadDeliveryNoteModalVisible(true)}>
                            <UploadOutlined /> <Trans>Upload delivery document</Trans>
                        </Button>
                    );
                }

                return undefined;
            }
        }
    );
    return columns;
};
