import { PlusOutlined } from '@ant-design/icons';
import { t, Trans } from '@lingui/macro';
import { Badge, Button, Col, PageHeader, Row, Select, Space, Tabs } from 'antd';
import { useState } from 'react';
import useGlobalState from 'src/app-react/hooks/useGlobalState';
import isRoleSelected from 'src/app-react/utils/isRoleSelected';
import ProjectModalForm from './ProjectModalForm/ProjectModalForm';
import ProjectsTable from './ProjectsTable/ProjectsTable';
import useProjectPageState from 'src/app-react/hooks/useProjectPageState';
import NotFound from 'src/app-react/components/NotFound/NotFound';
import NotFoundArchived from 'src/app-react/components/NotFound/NotFoundArchived';

function ProjectsPage() {
    const [isModalVisible, setModalVisibility] = useState(false);
    const { userProfile } = useGlobalState();

    const {
        reloadProjectPage,
        activeProjects,
        archivedProjects,
        setActiveProjectsPagination,
        setArchivedProjectsPagination,
        companyId,
        setCompanyId
    } = useProjectPageState();

    const showModal: () => void = () => setModalVisibility(true);
    const hideModal: () => void = () => setModalVisibility(false);

    const userHasProjectAdminRole = isRoleSelected({
        role: 'ProjectAdmin',
        companyId,
        userProfile
    });

    const items = [];
    if (userProfile) {
        items.push({
            key: 'Active',
            label: (
                <>
                    <Trans>Active</Trans>&nbsp;
                    <Badge count={activeProjects?.count} />
                </>
            ),
            children: activeProjects?.count ? (
                <ProjectsTable tab="Active" projects={activeProjects} setPagination={setActiveProjectsPagination} />
            ) : (
                <NotFound
                    message={t`You are currently not assigned to any active project with the email address ${userProfile.userEmail}.
  Please contact the project administrator to request permission.`}
                />
            )
        });

        items.push({
            key: 'Archived',
            label: (
                <>
                    <Trans>Archived</Trans>&nbsp;
                    <Badge count={archivedProjects?.count} />
                </>
            ),
            children: archivedProjects?.count ? (
                <ProjectsTable
                    tab="Archived"
                    projects={archivedProjects}
                    setPagination={setArchivedProjectsPagination}
                />
            ) : (
                <NotFoundArchived
                    message={t`You are currently not assigned to any archived project with the email address ${userProfile.userEmail}.
  Please contact the project administrator to request permission.`}
                />
            )
        });
    }

    return (
        <>
            <PageHeader title={t`Project list`} backIcon={false} />
            <Row wrap={false}>
                <Col flex="none">
                    <Trans>Selected company</Trans>
                </Col>
                <Col flex="auto">
                    <Select
                        data-testid="company-select"
                        defaultValue={companyId}
                        onChange={setCompanyId}
                        style={{ minWidth: '30%', marginLeft: 10 }}>
                        {userProfile.companies.map((company) => {
                            return (
                                <Select.Option key={company.companyId} value={company.companyId}>
                                    {company.companyName}
                                </Select.Option>
                            );
                        })}
                    </Select>
                </Col>
            </Row>
            <br />
            <div>
                <Tabs
                    items={items}
                    defaultActiveKey="Active"
                    onChange={() => {
                        reloadProjectPage();
                    }}
                    tabBarExtraContent={
                        <Space>
                            {userHasProjectAdminRole && (
                                <Button data-testid="open-project-modal-button" type="primary" onClick={showModal}>
                                    <PlusOutlined /> <Trans>Create Project</Trans>
                                </Button>
                            )}
                        </Space>
                    }
                />
            </div>
            <ProjectModalForm
                companyId={companyId}
                isEditMode={false}
                hideModal={hideModal}
                showModal={isModalVisible}
                onComplete={() => {
                    hideModal();
                    reloadProjectPage();
                }}
            />
        </>
    );
}

export default ProjectsPage;
