import { t } from '@lingui/macro';
import { Form } from 'antd';
import { InputNumber } from 'src/app-react/components/Form';
import numberRule from 'src/app-react/components/Form/Validations/numberRule';
import requiredRule from 'src/app-react/components/Form/Validations/requiredRule';
import useProjectConstantsState from 'src/app-react/hooks/useProjecsConstantsState';

function ConcretedVolumeFormItem() {
    const form = Form.useFormInstance();
    const signedOn = Form.useWatch('signedOn');
    const { units } = useProjectConstantsState();

    // we can´t use useWatch becouse the form item is not in the form.
    const volume = form?.getFieldValue('volume');

    return (
        <Form.Item
            label={t`Concreted quantity`}
            name="concretedVolume"
            rules={[
                { message: requiredRule.message, required: !!signedOn },
                numberRule({ minOrEqual: 0, max: volume })
            ]}>
            <InputNumber
                disabled={!signedOn}
                placeholder={t`Enter concreted quantity`}
                data-testid="concretedVolume"
                addonAfter={units.volume}
            />
        </Form.Item>
    );
}

export default ConcretedVolumeFormItem;
