/* eslint-disable jsx-a11y/anchor-is-valid */
import {
    BgColorsOutlined,
    CarryOutOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
    EllipsisOutlined,
    ExperimentOutlined,
    FileOutlined,
    FileSearchOutlined,
    UploadOutlined
} from '@ant-design/icons';
import { Trans, t } from '@lingui/macro';
import { BasicConcreteLoadWithOrderId, DeliveryStatus } from '@nexploretechnology/concreting-core-client';
import { Button, Dropdown, MenuProps, Space, Tag } from 'antd';
import { Link } from 'react-router-dom';
import DateFormatter from 'src/app-react/components/Formatters/DateFormatter';
import TimeFormatter from 'src/app-react/components/Formatters/TimeFormatter';
import { MARKETS } from 'src/app-react/constants/markets';
import useUserPermissions, { UserPermissions } from 'src/app-react/hooks/useUserPermissions';
import { formatNumberValueToLanguage } from 'src/app-react/utils/formatters';
import { ExtendedColumnType } from 'src/app-react/utils/pagination';
import { Modals } from '../../ActionModals/ActionModals';

interface GetActionsProps {
    record: BasicConcreteLoadWithOrderId;
    onModalAction: (record: BasicConcreteLoadWithOrderId, modal: Modals) => void;
    states: DeliveryStatus[];
    permissions?: UserPermissions;
    marketId: string;
}

const getActions = ({ record, onModalAction, states, permissions, marketId }: GetActionsProps): MenuProps['items'] => {
    const items: MenuProps['items'] = [];

    items.push({
        label: <Trans>Add concrete test</Trans>,
        key: `${record.id} Edit`,
        icon: <ExperimentOutlined />,
        onClick: () => {
            onModalAction(record, Modals.ConcreteTests);
        }
    });

    if (marketId !== MARKETS.DE) {
        items.push({
            label: <Trans>Add water on site</Trans>,
            key: `${record.id} Create`,
            icon: <BgColorsOutlined />,
            onClick: () => {
                onModalAction(record, Modals.DeliveryWaterModal);
            }
        });
    }

    if (permissions.isAllowToUploadDeliveryDocument) {
        items.push({
            label: <Trans>Upload delivery document</Trans>,
            key: `${record.id} Upload`,
            icon: <UploadOutlined />,
            disabled: record.attachmentBasicInfo !== undefined,
            onClick: () => {
                onModalAction(record, Modals.UploadDeliveryNoteModal);
            }
        });
    }

    if (
        !states.includes('Rejected') &&
        !states.includes('Signed') &&
        permissions?.isAllowToRejectConcreteDeliveryLoads
    ) {
        items.push({
            label: <Trans>Reject load</Trans>,
            key: `delete`,
            icon: <CloseCircleOutlined />,
            onClick: () => {
                onModalAction(record, Modals.RejectConcreteLoad);
            }
        });
    }

    return items;
};

const formatVolumeUnit = (unit: string) => unit?.toLowerCase()?.replace('3', '³');

interface deliveryDashboardTableColumnsDEProps {
    onModalAction: (record: BasicConcreteLoadWithOrderId, modal: Modals) => void;
    states: DeliveryStatus[];
    onDeliveryNoteDetails: (record: BasicConcreteLoadWithOrderId) => void;
    onGoToOrder: (record: BasicConcreteLoadWithOrderId) => void;
    downloadAttachmentClicked: (record: BasicConcreteLoadWithOrderId) => Promise<void>;
    marketId: string;
    permissions: UserPermissions;
}

export const deliveryDashboardTableColumns = ({
    onModalAction,
    onDeliveryNoteDetails,
    onGoToOrder,
    downloadAttachmentClicked,
    states,
    permissions,
    marketId
}: deliveryDashboardTableColumnsDEProps) => {
    const { isViewer } = useUserPermissions();

    const columns: ExtendedColumnType<BasicConcreteLoadWithOrderId>[] = [
        {
            title: <Trans>Delivery data</Trans>,
            children: [
                {
                    title: <Trans>Delivery date</Trans>,
                    dataIndex: 'loadingStart',
                    key: 'loadingStart',
                    sorter: true,
                    render: (_: any, record: BasicConcreteLoadWithOrderId) => (
                        <DateFormatter date={record.loadingStart} />
                    )
                },

                {
                    title: <Trans>Delivery note no.</Trans>,
                    dataIndex: 'deliveryNoteNumber',
                    key: 'deliveryNoteNumber',
                    sorter: true,
                    render: (_: any, record: BasicConcreteLoadWithOrderId) => {
                        if (record?.attachmentBasicInfo) {
                            return (
                                <span>
                                    <a
                                        onClick={() => {
                                            downloadAttachmentClicked(record);
                                        }}>
                                        {' '}
                                        {record.deliveryNoteNumber} <FileOutlined />
                                    </a>
                                </span>
                            );
                        }
                        return <span>{record.deliveryNoteNumber}</span>;
                    }
                },

                {
                    title: <Trans>License plate</Trans>,
                    dataIndex: 'truckLicensePlate',
                    key: 'truckLicensePlate',
                    sorter: true,
                    render: (_: any, record: BasicConcreteLoadWithOrderId) => <span>{record.truckLicensePlate}</span>
                },
                {
                    title: <Trans>Truck quantity</Trans>,
                    dataIndex: 'truckLicensePlate',
                    key: 'volume',
                    sorter: true,
                    render: (_: any, record: BasicConcreteLoadWithOrderId) => (
                        <span>
                            {formatNumberValueToLanguage(Math.round((record.volume || 0) * 10) / 10)}{' '}
                            {formatVolumeUnit(record.volumeUnit)}
                        </span>
                    )
                },

                {
                    title: <Trans>Concrete Type</Trans>,
                    dataIndex: 'concreteTypeNumber',
                    key: 'concreteTypeNumber',
                    sorter: true,
                    render: (_: any, record: BasicConcreteLoadWithOrderId) => (
                        <Link
                            onClick={(e) => {
                                e.preventDefault();
                                onModalAction(record, Modals.ConcreteTypeModal);
                            }}
                            to="">
                            {record.concreteTypeNumber}
                        </Link>
                    )
                }
            ]
        },

        {
            title: <Trans>Timestamps</Trans>,
            children: [
                {
                    title: <Trans>Loading time</Trans>,
                    dataIndex: 'loadingStart',
                    key: 'loadingStart',
                    sorter: true,
                    render: (_: any, record: BasicConcreteLoadWithOrderId) => (
                        <TimeFormatter date={record.loadingStart} />
                    )
                },

                {
                    title: <Trans>Processable until</Trans>,
                    dataIndex: 'processableUntil',
                    key: 'processableUntil',
                    sorter: true,
                    render: (_: any, record: BasicConcreteLoadWithOrderId) => (
                        <TimeFormatter date={record.processableUntil} />
                    )
                }
            ]
        },

        {
            title: <Trans>Tests</Trans>,
            children: [
                {
                    title: <Trans>Added test</Trans>,
                    dataIndex: 'loadingStart',
                    key: 'loadingStart',
                    render: (_: any, record: BasicConcreteLoadWithOrderId) =>
                        record.numberOfTests > 0 ? <Tag color="green">{t`Yes`}</Tag> : <Tag color="grey">{t`No`}</Tag>
                }
            ]
        }
    ];

    const actions = {
        title: '',
        children: [
            {
                title: <Trans>Actions</Trans>,
                dataIndex: 'actions',
                fixed: 'right',
                key: 'actions',
                render: (_: any, record: BasicConcreteLoadWithOrderId) => (
                    <Space direction="vertical" size="middle">
                        {states.includes('OnRoute') && (
                            <Button
                                data-testid="arrival"
                                hidden={isViewer}
                                type="link"
                                size="small"
                                onClick={() => {
                                    onModalAction(record, Modals.ReportArrival);
                                }}>
                                <CheckCircleOutlined /> <Trans>Report arrival</Trans>
                            </Button>
                        )}

                        {states.includes('Arrived') && (
                            <Button
                                data-testid="start-unloading"
                                hidden={isViewer}
                                type="link"
                                size="small"
                                onClick={() => {
                                    onModalAction(record, Modals.StartUnloading);
                                }}>
                                <CheckCircleOutlined /> <Trans>Start unloading</Trans>
                            </Button>
                        )}

                        {states.includes('Unloading') && (
                            <Button
                                data-testid="end-unloading"
                                hidden={isViewer}
                                type="link"
                                size="small"
                                onClick={() => {
                                    onModalAction(record, Modals.EndUnloading);
                                }}>
                                <CheckCircleOutlined /> <Trans>End unloading</Trans>
                            </Button>
                        )}

                        {states.includes('Unloaded') && (
                            <Button
                                data-testid="sign-unloading"
                                hidden={isViewer}
                                type="link"
                                size="small"
                                onClick={() => {
                                    onModalAction(record, Modals.AcceptAndSign);
                                }}>
                                <CheckCircleOutlined /> <Trans>Accept & Sign</Trans>
                            </Button>
                        )}

                        {marketId !== MARKETS.AUS && (
                            <Button
                                data-testid="gotoOrder"
                                type="link"
                                size="small"
                                onClick={() => {
                                    onGoToOrder(record);
                                }}>
                                <CarryOutOutlined /> <Trans>Go to order</Trans>
                            </Button>
                        )}

                        <Button
                            data-testid="details"
                            type="link"
                            size="small"
                            onClick={() => {
                                onDeliveryNoteDetails(record);
                            }}>
                            <FileSearchOutlined /> <Trans>Delivery note details</Trans>
                        </Button>
                    </Space>
                )
            },

            {
                title: <Trans>More</Trans>,
                dataIndex: 'more',
                fixed: 'right',
                isHidden: isViewer,
                key: 'more',
                render: (_: any, record: BasicConcreteLoadWithOrderId) => {
                    const items: MenuProps['items'] = getActions({
                        record,
                        onModalAction,
                        states,
                        permissions,
                        marketId
                    });
                    return (
                        <Dropdown key="moreButton" data-testid="moreButton" menu={{ items }} trigger={['click']}>
                            <EllipsisOutlined />
                        </Dropdown>
                    );
                }
            }
        ]
    };
    actions.children = actions.children.filter((item) => !item.isHidden);
    columns.push(actions as any);
    return columns;
};
