import {
    BasicConcreteLoadWithOrderId,
    DataList,
    DeliveryStatus,
    Direction,
    OffsetPagination
} from '@nexploretechnology/concreting-core-client';
import { BasicConcreteLoadData } from '@nexploretechnology/concreting-core-client/concrete/delivery.note.lite-client/delivery.note.lite.dto';
import { Table, TablePaginationConfig } from 'antd';
import { FilterValue, SorterResult, TableCurrentDataSource } from 'antd/lib/table/interface';
import debounce from 'lodash.debounce';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import useApi from 'src/app-react/hooks/useApi';
import useProjectState from 'src/app-react/hooks/useProjectState';
import useUserPermissions from 'src/app-react/hooks/useUserPermissions';
import { noPagination } from 'src/app-react/utils/pagination';
import { DEBOUNCE_TIME } from '../..';
import css from '../../../../styles/DeliveryDashbordPage.module.css';
import { Modals } from '../ActionModals/ActionModals';
import { deliveryDashboardTableColumns } from './DE/deliveryDashboardTableColumns';
import { DeliveryDashboardExpandedInfo } from './components/DeliveryDashboardExpandedInfo';
import { DeliveryDashboardContext } from 'src/app-react/business-page/DeliveryDashboardPage/context/DeliveryDahsboardPageContext';

type SortingType = Partial<Record<keyof BasicConcreteLoadData, Direction>> | undefined;

interface DeliveryDashboardTableProps {
    states: DeliveryStatus[];
    tab: string;
    selectedKey: string;
    onModalAction: (record: BasicConcreteLoadWithOrderId, modal: Modals) => void;
    refresh: boolean;
}

const defautPageSize = 10;

export function DeliveryDashboardTable(props: DeliveryDashboardTableProps) {
    const navigate = useNavigate();
    const { states, tab, selectedKey } = props;
    const { projectId, companyId, marketId } = useProjectState();
    const permissions = useUserPermissions();
    const api = useApi();
    const [tablePagination, setTablePagination] = useState({} as TablePaginationConfig);
    const { filter } = useContext(DeliveryDashboardContext);
    const queryPagination = useRef<OffsetPagination<BasicConcreteLoadData>>({
        ...noPagination,
        sorting: []
    });

    const [deliveries, setDeliveries] = useState<DataList<BasicConcreteLoadWithOrderId>>({
        count: 0,
        data: []
    });

    const [loadTable, setLoadTable] = useState(true);

    const loadDeliveries = useCallback(
        debounce(() => {
            api.deliveryNoteLiteClient
                .getConcreteLoadsByStateAndProject(companyId, projectId, states, queryPagination.current, filter)
                .then(async (response) => {
                    if (response.isSuccess()) {
                        setDeliveries(response.getEntity());
                        setTablePagination((prev) => {
                            const total = response.getEntity().count;
                            const pageSize = queryPagination.current.limit;
                            const maxPage = total / pageSize;
                            let page =
                                queryPagination.current.skip === 0 ? 1 : queryPagination.current.skip / pageSize + 1;
                            page = page > maxPage ? maxPage : page;
                            return {
                                position: ['bottomRight'],
                                defautPageSize,
                                current: Math.ceil(page),
                                total
                            } as TablePaginationConfig;
                        });
                        setLoadTable(false);
                    } else {
                        const msg = JSON.parse(response.getError());
                        ErrorNotification({ message: msg.error?.message ?? msg.message, description: '' });
                    }
                })
                .catch((info) => {
                    ErrorNotification({
                        message: info.errorFields[0].errors[0],
                        description: ''
                    });
                });
        }, DEBOUNCE_TIME),
        [filter]
    );

    useEffect(() => {
        if (tab === selectedKey) {
            queryPagination.current.skip = 0;
            setLoadTable(true);
            loadDeliveries();
        }
        // eslint-disable-next-line\ react-hooks/exhaustive-deps
    }, [projectId, companyId, filter, selectedKey, props.refresh]);

    const onDeliveryNoteDetails = (record: BasicConcreteLoadWithOrderId) => {
        navigate(`/projects/${projectId}/orders/${record.orderId}/delivery-notes-details/${record.id}/details`);
    };

    const onGoToOrder = (record: BasicConcreteLoadWithOrderId) => {
        navigate(`/projects/${projectId}/orders/${record.orderId}/delivery-notes-details`);
    };

    async function downloadAttachmentClicked(record: BasicConcreteLoadWithOrderId) {
        const fileResponse = await api.deliveryNoteClient.deliveryNoteGetFile(companyId, projectId, record.id);
        if (fileResponse.isSuccess()) {
            const { uri } = fileResponse.getEntity();
            window.open(uri, '_blank');
        }
    }

    const columns = deliveryDashboardTableColumns({
        permissions,
        onModalAction: props.onModalAction,
        states,
        onDeliveryNoteDetails,
        onGoToOrder,
        downloadAttachmentClicked,
        marketId
    });

    async function onChange(
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: SorterResult<BasicConcreteLoadWithOrderId> | SorterResult<BasicConcreteLoadWithOrderId>[],
        extra: TableCurrentDataSource<BasicConcreteLoadWithOrderId>
    ) {
        let sorting: SortingType;
        // eslint-disable-next-line no-param-reassign
        sorter = sorter as SorterResult<BasicConcreteLoadWithOrderId>;

        if (sorter.columnKey && sorter.order) {
            const sortDirection: string = { ascend: 'ASC', descend: 'DESC' }[sorter.order];
            const sortingValue = `{"${(sorter as SorterResult<BasicConcreteLoadWithOrderId>).columnKey}": "${
                sortDirection || 'DESC'
            }"}`;
            sorting = JSON.parse(sortingValue);
        }

        const queryParams: OffsetPagination<BasicConcreteLoadWithOrderId> = {
            skip: pagination.current! === 0 ? 0 : (pagination.current! - 1) * pagination.pageSize!,
            limit: pagination.pageSize!,
            sorting: sorting ? [sorting!] : []
        };

        queryPagination.current = queryParams;
        loadDeliveries();
    }

    return (
        <Table
            size="small"
            className={css.table}
            data-testid="deliveriesTable"
            rowClassName="deliveryRow"
            onHeaderRow={(col, index) => {
                return {
                    className: !index ? css.headerRow : css.secondHeaderRow
                };
            }}
            onRow={(record, rowIndex) => {
                return {
                    className: css.row
                };
            }}
            expandable={{
                // eslint-disable-next-line react/no-unstable-nested-components
                expandedRowRender: (record) => <DeliveryDashboardExpandedInfo record={record} />
            }}
            rowKey="id"
            columns={columns}
            dataSource={deliveries.data}
            loading={loadTable}
            // eslint-disable-next-line react/jsx-no-bind
            onChange={onChange}
            pagination={{ ...tablePagination, showSizeChanger: true }}
        />
    );
}
