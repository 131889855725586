import { UserCompany } from '@nexploretechnology/concreting-core-client/user.manager-client/user.manager.dto';
import { ROLES } from 'src/app-react/constants/roles';

function getProjectPermissions(userCompany: UserCompany, projectId: string) {
    const userProject = userCompany?.projects?.find((p) => p.projectId === projectId);
    const projectRoles = Object.keys(userProject?.projectRoles || {});
    const companyRoles = Object.keys(userCompany?.companyRoles || {});
    const roles = [...projectRoles, ...companyRoles];

    return {
        isForeman: roles.includes(ROLES.FOREMAN),
        isSubcontractor: roles.includes(ROLES.SUBCONTRACTOR),
        isViewer: roles.includes(ROLES.VIEWER),
        isProjectAdmin: roles.includes(ROLES.PROJECT_ADMIN),
        isProjectManager: roles.includes(ROLES.PROJECT_MANAGER),
        isConcreteEngineer: roles.includes(ROLES.CONCRETE_ENGINEER)
    };
}

export default getProjectPermissions;
