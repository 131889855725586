import { CloseCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { i18n } from '@lingui/core';
import { Trans, t } from '@lingui/macro';
import { InputAdmixture } from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/delivery.note.dto';
import { Button, Col, DatePicker, Form, Input, Modal, Row, notification } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import TimePicker from 'src/app-react/components/Form/TimePicker';
import ErrorNotification from 'src/app-react/components/Notification/ErrorNotification';
import useApi from 'src/app-react/hooks/useApi';
import useProjectConstantsState from 'src/app-react/hooks/useProjecsConstantsState';
import useProjectState from 'src/app-react/hooks/useProjectState';
import { parseDecimalByLocale } from 'src/app-react/utils/formatters';
import styles from './delivery-admixture-modal.module.css';
export interface DeliveryAdmixtureModalProps {
    orderId: string;
    deliveryNoteId: string;
    deliveryNoteNo: string;
    licensePlate: string;
    onComplete?: () => void;
    viewModal: boolean;
    setViewModal: React.Dispatch<React.SetStateAction<boolean>>;
}

interface FormAdmixture {
    name: string;
    mixtureDate: moment.Moment;
    mixtureTime: moment.Moment;
    amountOfConcrete: string;
    amountOfQuantity: string;
    comment: string;
}

const defaultMinimunMixingTimeValue = 5;

function DeliveryAdmixtureModal(props: DeliveryAdmixtureModalProps) {
    const { deliveryNoteId, orderId, deliveryNoteNo, licensePlate, onComplete, viewModal, setViewModal } = props;
    const { projectId, companyId } = useProjectState();
    const [form] = Form.useForm();
    const [minimunMixingTime, setMinimumMixingTime] = useState(defaultMinimunMixingTimeValue.toString());
    const api = useApi();

    const { formats } = useProjectConstantsState();

    let enabledButton = true;

    const dateHandler = (date: moment.Moment | null): void => {
        form.setFieldsValue({ date: date || moment() });
    };

    const timeHandler = (time: moment.Moment | null): void => {
        form.setFieldsValue({ time: time || moment() });
    };

    const calculateMinimumMixingTime = (): void => {
        const leftOverAmount: number = form.getFieldValue('amountOfConcrete') || 0;
        setMinimumMixingTime(Math.max(leftOverAmount, defaultMinimunMixingTimeValue).toString());
    };

    const convertFormToRequest = (formFilled: FormAdmixture): InputAdmixture => {
        return {
            concreteLoadId: deliveryNoteId,
            admixtureType: formFilled.name,
            amountOfConcrete: parseInt(formFilled.amountOfConcrete),
            amountOfConcreteUnits: 'm3',
            amountOfAdmixture: parseDecimalByLocale(formFilled.amountOfQuantity, i18n.locale),
            amountOfAdmixtureUnit: 'l',
            minimalMixingTimeMinutes: parseInt(minimunMixingTime),
            mixtureTime: moment(
                `${formFilled.mixtureDate.format('yyyy-MM-DD')} ${formFilled.mixtureTime.format('HH:mm')}`,
                'yyyy-MM-DD HH:mm'
            ).toDate()
        } as InputAdmixture;
    };

    const resetModal = (success: boolean) => {
        form.resetFields();
        setViewModal(false);

        if (success) {
            onComplete?.();
        }
    };

    const onSubmit = async (): Promise<void> => {
        const formFilled: FormAdmixture = form.getFieldsValue();
        form.validateFields()
            .then(async () => {
                if (enabledButton === true) {
                    enabledButton = false;

                    try {
                        const data: InputAdmixture = convertFormToRequest(formFilled);
                        await api.deliveryNoteClient
                            .createAdmmixture(companyId, projectId, orderId, deliveryNoteId, data)
                            .then((response) => {
                                if (response.isSuccess()) {
                                    notification.success({
                                        message: t`Admixture added successfully`,
                                        description: ''
                                    });
                                    resetModal(true);
                                } else {
                                    ErrorNotification({
                                        message: response.getError(),
                                        description: ''
                                    });
                                    resetModal(true);
                                }
                            });
                    } catch (e: any) {
                        ErrorNotification({ message: e.code, description: e.message });
                        enabledButton = true;
                        resetModal(true);
                    }
                }
            })
            .catch((e: any) => {
                ErrorNotification({
                    message: t`Form not filled correctly`,
                    description: t`Please, make sure that all the fields are filled correctly`
                });
            });
    };
    const onCancel = (): void => {
        form.resetFields();
        setViewModal(false);
    };

    return (
        <>
            {!viewModal ? null : (
                <Modal
                    maskClosable={false}
                    title={t`Add admixture`}
                    open={viewModal}
                    onCancel={onCancel}
                    width={600}
                    footer={[
                        <Button data-testid="close-btn" key="close-btn" onClick={onCancel} type="default">
                            <CloseCircleOutlined /> <Trans>Cancel</Trans>
                        </Button>,

                        <Button
                            data-testid="submit-btn"
                            key="confirm-btn"
                            onClick={onSubmit}
                            type="primary"
                            disabled={!enabledButton}>
                            <PlusCircleOutlined /> <Trans>Add admixture</Trans>
                        </Button>
                    ]}>
                    <p>
                        <Trans>Please enter the admixture details added to the concreted load</Trans>
                    </p>
                    <Form form={form} data-testid="deliveryAdmixtureModalForm" name="deliveryAdmixtureModalForm">
                        <Row gutter={[48, 16]}>
                            <Col span={12}>
                                <h4 className={styles.inputTitle}>
                                    <Trans>Delivery note number</Trans>
                                </h4>
                                <h3>{deliveryNoteNo}</h3>
                            </Col>
                            <Col span={12}>
                                <h4 className={styles.inputTitle}>
                                    <Trans>License plate</Trans>
                                </h4>
                                <h3>{licensePlate}</h3>
                            </Col>
                        </Row>
                        <Row gutter={[48, 16]}>
                            <Col span={24}>
                                <Form.Item
                                    name="mixtureDate"
                                    label={<Trans>Admixture added date</Trans>}
                                    className={styles.inline}
                                    initialValue={moment()}
                                    rules={[{ required: true, message: t`Please choose a date` }]}>
                                    <DatePicker
                                        style={{ width: '100%' }}
                                        placeholder={t`Enter Date`}
                                        format={formats.dateForm}
                                        onChange={dateHandler}
                                        data-testid="mixtureDate"
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[48, 16]}>
                            <Col span={24}>
                                <Form.Item
                                    name="mixtureTime"
                                    label={<Trans>Admixture added time</Trans>}
                                    className={styles.inline}
                                    initialValue={moment()}
                                    rules={[{ required: true, message: t`Please choose a date` }]}>
                                    <TimePicker onChange={timeHandler} data-testid="mixtureTime" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[48, 16]}>
                            <Col span={24}>
                                <Form.Item
                                    name="name"
                                    label={<Trans>Name of admixture</Trans>}
                                    className={styles.inline}
                                    rules={[
                                        {
                                            required: true,
                                            message: (
                                                <span data-testid="messageNameAdmixture">
                                                    <Trans>This field is required</Trans>
                                                </span>
                                            )
                                        }
                                    ]}>
                                    <Input type="text" placeholder={t`Enter name of admixture`} data-testid="name" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[48, 16]}>
                            <Col span={24}>
                                <Form.Item
                                    name="amountOfConcrete"
                                    label={<Trans>Remaining concrete in truck mixer</Trans>}
                                    className={styles.inline}
                                    rules={[
                                        {
                                            required: true,
                                            message: t`This field is required`
                                        },
                                        {
                                            pattern: new RegExp(/^[1-9]\d*$/),
                                            message: t`Please input a valid concrete amount`
                                        }
                                    ]}>
                                    <Input
                                        type="number"
                                        min={0}
                                        addonAfter={`m3`}
                                        placeholder={t`Enter remaining concrete amount`}
                                        data-testid="amountOfConcrete"
                                        onChange={calculateMinimumMixingTime}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={[48, 16]}>
                            <Col span={24}>
                                <Form.Item
                                    name="amountOfQuantity"
                                    label={<Trans>Admixture quantity</Trans>}
                                    className={styles.inline}
                                    rules={[
                                        { required: true, message: t`This field is required` },
                                        {
                                            pattern: new RegExp(/^\d+\.?\d*/),
                                            message: t`Please input valid quantity`
                                        }
                                    ]}>
                                    <Input
                                        type="number"
                                        min={0}
                                        addonAfter="l"
                                        placeholder={t`Enter admixture quantity`}
                                        data-testid="amountOfQuantity"
                                    />
                                </Form.Item>
                                <p className={styles.minimalTime}>
                                    <Trans>Minimal mixing time: </Trans>
                                    <b>
                                        {minimunMixingTime} <Trans>minutes</Trans>
                                    </b>
                                </p>
                            </Col>
                        </Row>
                        {/*
                    <!-- Commented due to: https://nexplore.atlassian.net/browse/NB-3958
                    <Row gutter={[48, 16]}>
                        <Col span={24}>
                            <h4 className='input-title'><Trans>Comment (Optional)</Trans> </h4>
                            <Form.Item name="comment">
                                    <TextArea
                                        rows={4}
                                        placeholder={t`Enter here any remark you might have`}
                                        data-testid="comment"
                                    />
                            </Form.Item>
                        </Col>
                    </Row> */}
                    </Form>
                </Modal>
            )}
        </>
    );
}

export default DeliveryAdmixtureModal;
