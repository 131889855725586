import { Col, Form, Row } from 'antd';
import { SupplierUIFormItem } from '../../../../Form/components/FormItems/SupplierUIFormItem';
import { PasswordFormItem } from '../../../FormItems/PasswordFormItem';
import { SupplierUrlFormItem } from '../../../FormItems/SupplierUrlFormItem';
import { TypeSusbcriptionFormItem } from '../../../FormItems/TypeSusbcriptionFormItem';
import { UsernameFormItem } from '../../../FormItems/UsernameFormItem';
import { SubscriptionKeyFormItem } from '../../../FormUS/components/FormItems/SubscriptionKeyFormItem';
import { CustomerIdFormItem } from '../FormItems/CustomerIdFormItem';
import { SupplierProjectIdFormItem } from '../FormItems/SupplierProjectIdFormItem';

export function SupplierConfigFormLayoutConcreteDirectV2DE() {
    const form = Form.useFormInstance();
    const selectedSupplierSoftware = Form.useWatch('supplierSoftware', form);

    return (
        <>
            <Row gutter={8}>
                <Col span={11}>
                    <SupplierUrlFormItem />
                </Col>
                <Col span={13}>
                    <SupplierProjectIdFormItem />
                </Col>
            </Row>
            <Row gutter={8}>
                <Col span={11}>
                    <SubscriptionKeyFormItem />
                </Col>
                <Col span={13}>
                    <CustomerIdFormItem />
                </Col>
            </Row>
            <Row gutter={8}>
                <Col span={11}>
                    <UsernameFormItem />
                </Col>
                <Col span={13}>
                    <PasswordFormItem />
                </Col>
            </Row>
            <Row gutter={8} hidden={selectedSupplierSoftware != 'holcim.concrete.direct.v2'}>
                <Col span={11}>
                    <TypeSusbcriptionFormItem />
                </Col>
            </Row>
            <Row gutter={8}>
                <SupplierUIFormItem />
            </Row>
        </>
    );
}
