import { createContext, useEffect, useMemo, useReducer, useState } from 'react';
import useGlobalState from '../hooks/useGlobalState';
import { Project } from '@nexploretechnology/concreting-core-client/concrete/project.management-client/project.management.dto';
import { DataList, OffsetPagination } from '@nexploretechnology/concreting-core-client/pagination';
import useLocalStorage from '../hooks/useLocalStorage';
import { defaultPagination } from '../utils/pagination';

export interface ProjectPageContextValue {
    companyId?: string;
    setCompanyId?: React.Dispatch<string>;
    activeProjects?: DataList<Project>;
    setActiveProjectsPagination?: React.Dispatch<OffsetPagination<Project>>;
    archivedProjects?: DataList<Project>;
    setArchivedProjectsPagination?: React.Dispatch<OffsetPagination<Project>>;
    loading?: boolean;
    reloadProjectPage?: () => void;
}

const ProjectPageContext = createContext<ProjectPageContextValue>({});

function ProjectPageProvider({ children }: { children: unknown }) {
    const [activeProjects, setActiveProjects] = useState<DataList<Project>>(undefined);
    const [activeProjectsPagination, setActiveProjectsPagination] =
        useState<OffsetPagination<Project>>(defaultPagination);
    const [archivedProjects, setArchivedProjects] = useState<DataList<Project>>(undefined);
    const [archivedProjectsPagination, setArchivedProjectsPagination] =
        useState<OffsetPagination<Project>>(defaultPagination);

    const [reloaded, reloadProjectPage] = useReducer((x) => x + 1, 0);
    const [loading, setLoading] = useState<boolean>(false);
    const [localStorageCompanyId, setLocalStorageCompanyId] = useLocalStorage('preferredCompany');
    const { userProfile } = useGlobalState();
    const [companyId, setCompanyId] = useState(
        userProfile?.companies?.find((c) => c.companyId === localStorageCompanyId)
            ? localStorageCompanyId
            : userProfile?.companies?.[0]?.companyId
    );

    const { api } = useGlobalState();

    useEffect(() => {
        setLocalStorageCompanyId(companyId);
    }, [companyId, setLocalStorageCompanyId]);

    useEffect(() => {
        // interval to load at 20000 milliseconds
        const interval = setInterval(() => {
            reloadProjectPage();
        }, 20000);

        return () => clearInterval(interval);
    }, [reloadProjectPage]);

    useEffect(() => {
        if (companyId) {
            setLoading(true);
            (async () => {
                await api.projectManagementClient
                    .getProjectsAsDataList(companyId, activeProjectsPagination, '', false)
                    .then((response) => {
                        if (response.isSuccess()) {
                            setActiveProjects(response.getEntity());
                        }
                    });
                await api.projectManagementClient
                    .getProjectsAsDataList(companyId, archivedProjectsPagination, '', true)
                    .then((response) => {
                        if (response.isSuccess()) {
                            setArchivedProjects(response.getEntity());
                        }
                    });
            })().finally(() => {
                setLoading(false);
            });
        }
    }, [
        api,
        reloaded,
        companyId,
        setActiveProjects,
        activeProjectsPagination,
        setArchivedProjects,
        archivedProjectsPagination
    ]);

    const value = useMemo(
        () => ({
            loading,
            reloadProjectPage,
            activeProjects,
            setActiveProjectsPagination,
            archivedProjects,
            setArchivedProjectsPagination,
            companyId,
            setCompanyId
        }),
        [
            loading,
            reloadProjectPage,
            activeProjects,
            setActiveProjectsPagination,
            archivedProjects,
            setArchivedProjectsPagination,
            companyId,
            setCompanyId
        ]
    );

    return <ProjectPageContext.Provider value={value}>{children}</ProjectPageContext.Provider>;
}

export { ProjectPageContext, ProjectPageProvider };
