import { Trans, t } from '@lingui/macro';
import { Form, Input } from 'antd';
import FormItem from 'antd/lib/form/FormItem';

export function PasswordFormItem() {
    const form = Form.useFormInstance();
    const selectedSupplierSoftware = Form.useWatch('supplierSoftware', form);

    return (
        <FormItem
            label={
                selectedSupplierSoftware == 'holcim.concrete.direct.v2' ? (
                    <Trans>client_secret</Trans>
                ) : (
                    <Trans>Password</Trans>
                )
            }
            name="password"
            rules={[
                {
                    required: true,
                    message: <Trans>This field is required</Trans>
                }
            ]}>
            <Input type="password" data-testid="password" placeholder={t`Enter user password`} />
        </FormItem>
    );
}
