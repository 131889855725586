import { t, Trans } from '@lingui/macro';
import { Form, Input } from 'antd';
import FormItem from 'antd/lib/form/FormItem';

export function SubscriptionKeyFormItem() {
    const form = Form.useFormInstance();
    const selectedSupplierSoftware = Form.useWatch('supplierSoftware', form);

    return (
        <FormItem
            name="subscriptionKey"
            label={
                selectedSupplierSoftware == 'holcim.concrete.direct.v2' ? (
                    <Trans>x_rsm_int_api_key</Trans>
                ) : (
                    <Trans>Subscription Key</Trans>
                )
            }
            rules={[
                {
                    required: true,
                    message: <Trans>This field is required</Trans>
                }
            ]}>
            <Input type="text" data-testid="subscriptionKey" placeholder={t`Enter subscription key`} />
        </FormItem>
    );
}
