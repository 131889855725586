import { Trans } from '@lingui/macro';
import { Empty } from 'antd';

function NotFoundArchived({ message }: { message?: string }) {
    return (
        <div style={{ textAlign: 'center', marginTop: '10%' }}>
            <p style={{ fontWeight: 'bold', color: '#1F3855' }}>{message || <Trans>Page not found</Trans>}</p>
            <Empty />
        </div>
    );
}

export default NotFoundArchived;
