import { Trans, t } from '@lingui/macro';
import { Form, Input } from 'antd';
import FormItem from 'antd/lib/form/FormItem';

export function UsernameFormItem() {
    const form = Form.useFormInstance();
    const selectedSupplierSoftware = Form.useWatch('supplierSoftware', form);

    return (
        <FormItem
            label={
                selectedSupplierSoftware == 'holcim.concrete.direct.v2' ? (
                    <Trans>client_user_id</Trans>
                ) : (
                    <Trans>Username</Trans>
                )
            }
            name="username"
            rules={[
                {
                    required: true,
                    message: <Trans>This field is required</Trans>
                }
            ]}>
            <Input type="text" data-testid="username" placeholder={t`Enter Username`} />
        </FormItem>
    );
}
