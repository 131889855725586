/* eslint-disable no-use-before-define */
import {
    CheckCircleOutlined,
    CloseCircleOutlined,
    EllipsisOutlined,
    ExperimentOutlined,
    FileOutlined,
    FileSyncOutlined,
    FilterOutlined,
    UploadOutlined
} from '@ant-design/icons';
import { Trans, t } from '@lingui/macro';
import { DeliveryNoteBP } from '@nexploretechnology/concreting-core-client/concrete/delivery.note-client/delivery.note.dto';
import { Button, Dropdown, Input, MenuProps, Space, Tag } from 'antd';
import { NumberFormatter } from 'src/app-react/components/Formatters';
import TimeFormatter from 'src/app-react/components/Formatters/TimeFormatter';
import useUserPermissions, { UserPermissions } from 'src/app-react/hooks/useUserPermissions';
import { ExtendedColumnType } from 'src/app-react/utils/pagination';

const marketIdDE = 'DE';
export interface StatusTableColumns {
    displayRejectConcreteLoadModal: (concreteLoadId: string) => void;
    displayConcreteTestModal: (dnId: string, dnNo: string) => void;
    displayReportArrivalModal: (dnId: string, dnNo: string, licensePlate: string) => void;
    displayStartUnloadingModal: (
        dnId: string,
        dnNo: string,
        licensePlate: string,
        arrivalDateTime: Date,
        processableUntil: Date
    ) => void;
    displayEndUnloadingModal: (dnId: string, dnNo: string, licensePlate: string, startUnloadingDateTime: Date) => void;
    displayAcceptAndSignModal: (dnId: string) => void;
    displayUploadDeliveryModal: (dnId: string) => void;
    openDeliveryDetailsPage: (dnId: string) => void;
    projectId: string;
    marketId: string;

    filterDeliveryNoteNumber: string;
    setFilterDeliveryNoteNumber: React.Dispatch<React.SetStateAction<string>>;
    filterDeliveryNoteNumberClicked: React.MouseEventHandler<HTMLAnchorElement>;
    filterTruckLicensePlate: string;
    setFilterTruckLicensePlate: React.Dispatch<React.SetStateAction<string>>;
    filterTruckLicensePlateClicked: React.MouseEventHandler<HTMLAnchorElement>;
    permissions?: UserPermissions;
    downloadAttachmentClicked: (record: DeliveryNoteBP) => Promise<void>;
}

export const deliveryStatusTableColumns: (props: StatusTableColumns) => ExtendedColumnType<DeliveryNoteBP>[] = (
    props: StatusTableColumns
) => {
    const {
        displayRejectConcreteLoadModal,
        displayConcreteTestModal,
        displayReportArrivalModal,
        displayStartUnloadingModal,
        displayEndUnloadingModal,
        displayAcceptAndSignModal,
        displayUploadDeliveryModal,
        openDeliveryDetailsPage,
        marketId,
        filterDeliveryNoteNumber,
        setFilterDeliveryNoteNumber,
        filterDeliveryNoteNumberClicked,
        filterTruckLicensePlate,
        setFilterTruckLicensePlate,
        filterTruckLicensePlateClicked,
        permissions,
        downloadAttachmentClicked
    } = props;

    const getActions = ({ record }: { record: DeliveryNoteBP }): MenuProps['items'] => {
        const items: MenuProps['items'] = [];

        if (permissions.isAllowToUploadDeliveryDocument) {
            items.push({
                label: <Trans>Upload delivery document</Trans>,
                key: `${record.dnId} Upload`,
                icon: <UploadOutlined />,
                disabled: record.attachmentBasicInfo !== undefined,
                onClick: () => {
                    displayUploadDeliveryModal(record.dnId);
                }
            });
        }

        return items;
    };

    const setStatusText = (status: string): string => {
        let statusText = '';
        switch (status) {
            case 'Loading':
                statusText = t`Loading`;
                break;
            case 'OnRoute':
                statusText = t`On route`;
                break;
            case 'Arrived':
            case 'ReleasedForUnloading':
                statusText = t`Arrived`;
                break;
            case 'Unloading':
                statusText = t`Unloading`;
                break;
            case 'Unloaded':
                statusText = t`Unloaded`;
                break;
            case 'Rejected':
                statusText = t`Rejected`;
                break;
            case 'Signed':
                statusText = t`Signed`;
                break;
            case 'DeliveryNoteReceived':
            default:
                statusText = t`Delivery scheduled`;
                break;
        }

        return statusText;
    };

    const setActionButtons = (row: DeliveryNoteBP): JSX.Element => {
        // eslint-disable-next-line react/jsx-no-useless-fragment
        let button: JSX.Element = <></>;

        switch (row.status) {
            case 'Arrived':
            case 'ReleasedForUnloading':
                button = (
                    <>
                        {permissions?.isAllowToStartUnloadingConcreteDeliveryLoads && (
                            <Button
                                data-testid="unloading-btn"
                                type="link"
                                size="small"
                                onClick={() =>
                                    displayStartUnloadingModal(
                                        row.dnId,
                                        row.deliveryNoteNumber,
                                        row.truckLicensePlate,
                                        row.arrivalAtConstructionSite,
                                        row.processableUntil
                                    )
                                }>
                                <CheckCircleOutlined /> <Trans>Start unloading</Trans>
                            </Button>
                        )}
                        {displayConcreteTestButton(row.dnId, row.deliveryNoteNumber)}
                        {permissions?.isAllowToRejectConcreteDeliveryLoads
                            ? displayRejectConcreteLoadButton(row.dnId)
                            : null}
                    </>
                );
                break;
            case 'Unloading':
                button = (
                    <>
                        {permissions?.isAllowToEndUnloadingConcreteDeliveryLoads && (
                            <Button
                                data-testid="unloaded-btn"
                                type="link"
                                size="small"
                                onClick={() =>
                                    displayEndUnloadingModal(
                                        row.dnId,
                                        row.deliveryNoteNumber,
                                        row.truckLicensePlate,
                                        row.unloadingStart
                                    )
                                }>
                                <CheckCircleOutlined /> <Trans>End unloading</Trans>
                            </Button>
                        )}
                        {displayConcreteTestButton(row.dnId, row.deliveryNoteNumber)}
                        {permissions?.isAllowToRejectConcreteDeliveryLoads
                            ? displayRejectConcreteLoadButton(row.dnId)
                            : null}
                    </>
                );
                break;
            case 'Unloaded':
                button = (
                    <>
                        {permissions?.isAllowToAcceptConcreteDeliveryLoads && (
                            <Button
                                data-testid="sign-btn"
                                type="link"
                                size="small"
                                onClick={() => displayAcceptAndSignModal(row.dnId)}>
                                <CheckCircleOutlined />{' '}
                                {marketId === marketIdDE ? <Trans>Accept and sign</Trans> : <Trans>Accept</Trans>}
                            </Button>
                        )}
                        {displayConcreteTestButton(row.dnId, row.deliveryNoteNumber)}
                        {permissions?.isAllowToRejectConcreteDeliveryLoads
                            ? displayRejectConcreteLoadButton(row.dnId)
                            : null}

                        <Button
                            hidden
                            data-testid="upload-file"
                            type="link"
                            size="small"
                            onClick={() => displayUploadDeliveryModal(row.dnId)}>
                            <UploadOutlined /> <Trans>Upload file</Trans>
                        </Button>
                    </>
                );
                break;
            case 'Signed':
                button = <>{displayConcreteTestButton(row.dnId, row.deliveryNoteNumber)}</>;
                break;
            case 'Rejected':
                // eslint-disable-next-line react/jsx-no-useless-fragment
                button = <></>;
                break;
            case 'DeliveryNoteReceived':
            case 'Loading':
            case 'OnRoute':
            default:
                button = (
                    <>
                        {permissions?.isAllowToReportArrivalConcreteDeliveryLoads && (
                            <Button
                                data-testid="arrival-btn"
                                type="link"
                                size="small"
                                onClick={() =>
                                    displayReportArrivalModal(row.dnId, row.deliveryNoteNumber, row.truckLicensePlate)
                                }>
                                <CheckCircleOutlined /> <Trans>Report arrival</Trans>
                            </Button>
                        )}
                        {displayConcreteTestButton(row.dnId, row.deliveryNoteId)}
                        {permissions?.isAllowToRejectConcreteDeliveryLoads
                            ? displayRejectConcreteLoadButton(row.dnId)
                            : null}
                    </>
                );
                break;
        }

        return (
            <Space direction="horizontal" wrap size="large">
                {button}
            </Space>
        );
    };

    const displayConcreteTestButton = (dnId: string, dnNo: string): JSX.Element => {
        return (
            <Button
                data-testid="concreteTestButton"
                type="link"
                size="small"
                onClick={() => displayConcreteTestModal(dnId, dnNo)}>
                <ExperimentOutlined /> <Trans>Concrete test</Trans>
            </Button>
        );
    };

    const displayRejectConcreteLoadButton = (concreteLoadId: string): JSX.Element => {
        return (
            <Button
                data-testid="rejectConcreteLoadButton"
                type="link"
                size="small"
                onClick={() => displayRejectConcreteLoadModal(concreteLoadId)}>
                <CloseCircleOutlined /> <Trans>Reject load</Trans>
            </Button>
        );
    };

    const { isViewer } = useUserPermissions();

    const columns: ExtendedColumnType<DeliveryNoteBP>[] = [
        {
            title: marketId === marketIdDE ? <Trans>Delivery note no.</Trans> : <Trans>Ticket no.</Trans>,
            key: 'deliveryNoteNumber',
            dataIndex: 'deliveryNoteNumber',
            width: 200,
            sorter: true,
            render: (deliveryNoteNumber: string, record: DeliveryNoteBP) => {
                if (record?.attachmentBasicInfo && permissions.isAllowToDownloadDeliveryDocument) {
                    return (
                        <span>
                            <a
                                onClick={() => {
                                    downloadAttachmentClicked(record);
                                }}>
                                {record.deliveryNoteNumber}
                                <FileOutlined />
                            </a>
                        </span>
                    );
                }
                return <span>{record.deliveryNoteNumber}</span>;
            },
            filterDropdown: () => (
                <Space style={{ padding: 8 }} data-testid="deliveryNoteNumberFilter">
                    <Input
                        placeholder={t`Filter by delivery note no.`}
                        value={filterDeliveryNoteNumber}
                        data-testid="deliveryNoteNumberFilterInput"
                        onChange={(event) => {
                            setFilterDeliveryNoteNumber(event.target.value);
                        }}
                        onPressEnter={(event: any) => {
                            event.stopPropagation();
                            filterDeliveryNoteNumberClicked(event);
                        }}
                    />
                    <Button
                        data-testid="deliveryNoteNumberFilterButton"
                        onClick={filterDeliveryNoteNumberClicked}
                        type="primary"
                        size="middle"
                        icon={<FilterOutlined />}>
                        {' '}
                        <Trans id="Filter" />
                    </Button>
                </Space>
            )
        },

        {
            title: marketId === marketIdDE ? <Trans>License plate</Trans> : <Trans>Truck number.</Trans>,
            key: 'truckLicensePlate',
            dataIndex: 'truckLicensePlate',
            width: 150,
            sorter: true,
            render: (truckLicensePlate: string) => <span data-testid="truckLicensePlate">{truckLicensePlate}</span>,
            filterDropdown: () => (
                <Space style={{ padding: 8 }} data-testid="truckLicensePlateFilter">
                    <Input
                        placeholder={marketId === marketIdDE ? t`Filter by license plate` : t`Filter by truck number`}
                        value={filterTruckLicensePlate}
                        data-testid="truckLicensePlateFilterInput"
                        onChange={(event) => {
                            setFilterTruckLicensePlate(event.target.value);
                        }}
                        onPressEnter={(event: any) => {
                            event.stopPropagation();
                            filterTruckLicensePlateClicked(event);
                        }}
                    />
                    <Button
                        data-testid="truckLicensePlateFilterButton"
                        onClick={filterTruckLicensePlateClicked}
                        type="primary"
                        size="middle"
                        icon={<FilterOutlined />}>
                        {' '}
                        <Trans id="Filter" />
                    </Button>
                </Space>
            )
        },

        {
            title: <Trans>Quantity</Trans>,
            key: 'volume',
            dataIndex: 'volume',
            width: 120,
            sorter: true,
            render: (volume: number, row: DeliveryNoteBP) => <NumberFormatter value={volume} unit={row.volumeUnit} />
        },

        {
            title: <Trans>Loading Time</Trans>,
            key: 'loadingStart',
            dataIndex: 'loadingStart',
            width: 150,
            sorter: true,
            render: (loadingStart: string) => (
                <span className="deliveryStatusDate">
                    <TimeFormatter date={loadingStart} />
                </span>
            )
        },

        {
            title: <Trans>Process Until</Trans>,
            key: 'processableUntil',
            dataIndex: 'processableUntil',
            width: 150,
            sorter: true,
            render: (processableUntil: string) => (
                <span className="deliveryStatusDate">
                    <TimeFormatter date={processableUntil} />
                </span>
            )
        },

        {
            title: <Trans>Status</Trans>,
            key: 'deliveryStatus',
            dataIndex: 'deliveryStatus',
            sorter: false,
            render: (deliveryStatus: DeliveryNoteBP[], row: DeliveryNoteBP) => (
                <Tag data-testid="status-tag">{setStatusText(row.status)}</Tag>
            )
        },

        {
            title: <Trans>Actions</Trans>,
            key: 'actions',
            hidden: isViewer,
            dataIndex: 'actions',
            sorter: false,
            render: (dnId: string, row: DeliveryNoteBP) => setActionButtons(row)
        },

        {
            title: marketId === marketIdDE ? <Trans>Delivery note details</Trans> : <Trans>Concrete load</Trans>,
            key: 'dnId',
            dataIndex: 'dnId',
            render: (dnId: string, record: DeliveryNoteBP) => (
                <Button
                    data-testid="details-btn"
                    type="primary"
                    size="middle"
                    onClick={() => openDeliveryDetailsPage(dnId)}>
                    <FileSyncOutlined />{' '}
                    {marketId === marketIdDE ? <Trans>Delivery Details</Trans> : <Trans>Concrete load</Trans>}
                </Button>
            )
        },

        {
            title: <Trans>More</Trans>,
            dataIndex: 'more',
            hidden: isViewer,
            key: 'more',
            render: (_: any, record: DeliveryNoteBP) => {
                const items: MenuProps['items'] = getActions({ record });
                return (
                    <Dropdown key="moreButton" data-testid="moreButton" menu={{ items }} trigger={['click']}>
                        <EllipsisOutlined />
                    </Dropdown>
                );
            }
        }
    ];

    return columns.filter((item) => !item.hidden);
};
