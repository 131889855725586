import { MinusSquareOutlined, PlusSquareOutlined } from "@ant-design/icons";
import { Trans, t } from "@lingui/macro";
import { Button, Col, Form, Input, Row } from "antd";
import InputFormItem from "src/app-react/components/Form/FormItem/InputFormItem";
import useProjectConstantsState from "src/app-react/hooks/useProjecsConstantsState";

export function SupplementaryCementiousMaterialsFormItem(): JSX.Element {
    const { units } = useProjectConstantsState();

    return (
        <Form.List name="supplementaryCementiousMaterials">
            {(fields, { add, remove }) => (
                <>
                    {fields.map((field) => (
                        <div key={field.key}>
                            <Row>
                                <Col flex="auto">
                                    <div style={field.name === 0 ? {} : { visibility: 'hidden' }}>
                                        <label className="concrete-type-modal__form-list-title">
                                            <Trans>Supplementary cementious material(s)</Trans>
                                        </label>
                                    </div>
                                </Col>
                                <Col flex="none">
                                    <Form.Item
                                        name={[field.name, 'remove']}
                                    >
                                        <Button
                                            className="concrete-type-modal__form-list-remove-button"
                                            type="link"
                                            size="small"
                                            onClick={() => {
                                                remove(field.name);
                                            }}>
                                            <MinusSquareOutlined />
                                            <Trans>Remove</Trans>
                                        </Button>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    <Row gutter={12}>
                                        <Col span={8}>
                                            <Form.Item
                                                name={[field.name, 'type']}
                                                label={
                                                    <label>
                                                        <Trans>Type</Trans>
                                                    </label>
                                                }
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: t`This field is required`
                                                    }
                                                ]}>
                                                <Input data-testid="type" placeholder={t`Enter type`} />
                                            </Form.Item>
                                        </Col>
                                        <Col span={8}>
                                            <InputFormItem
                                                required={false}
                                                name={[field.name, 'origin']}
                                                label={t`Origin`}>
                                                <Input data-testid="origin" placeholder={t`Enter origin`} />
                                            </InputFormItem>
                                        </Col>
                                        <Col span={8}>
                                            <InputFormItem
                                                required={false}
                                                name={[field.name, 'quantity']}
                                                label={t`Quantity`}
                                                rules={[
                                                    {
                                                        pattern: new RegExp(/^-?\d+(?:\.\d{1,2})?$/),
                                                        message: t`Please enter the quantity`
                                                    }
                                                ]}>
                                                <Input
                                                    type="number"
                                                    data-testid="quantity"
                                                    placeholder={t`Enter quantity`}
                                                    addonAfter={units.density}
                                                />
                                            </InputFormItem>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col flex="auto" />
                            </Row>
                        </div>
                    ))}

                    <Form.Item>
                        <Button
                            data-testid="supplementaryCementiousMaterials"
                            className="concrete-type-modal__form-list-add-button"
                            type="link"
                            size="small"
                            onClick={() => {
                                add({});
                            }}>
                            <PlusSquareOutlined /> <Trans>Add supplementary cementious material</Trans>
                        </Button>
                    </Form.Item>
                </>
            )}
        </Form.List>
    );
}
