import {
    EditOutlined,
    EllipsisOutlined,
    ImportOutlined,
    InboxOutlined,
    SettingOutlined,
    UserOutlined
} from '@ant-design/icons';
import { Trans } from '@lingui/macro';
import { Project } from '@nexploretechnology/concreting-core-client/concrete/project.management-client/project.management.dto';
import { UserCompany } from '@nexploretechnology/concreting-core-client/user.manager-client/user.manager.dto';
import { Button, Dropdown, MenuProps } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import DateFormatter from 'src/app-react/components/Formatters/DateFormatter';
import getProjectPermissions from '../../utils/getProjectPermissions';

export interface ProjectsTableColumnsProps {
    userCompany: UserCompany;
    onEditProject: (projectId: string) => void;
    onEnterProject: (projectId: string, marketId?: string) => void;
    onEditSupplier: (projectId: string) => void;
    onManageUsers: (projectId: string) => void;
    onArchiveProject: (project: Project) => void;
}

export const projectsTableColumns: (props: ProjectsTableColumnsProps) => ColumnsType<Project> = ({
    onEditProject,
    onEnterProject,
    onEditSupplier,
    onManageUsers,
    onArchiveProject,
    userCompany
}: ProjectsTableColumnsProps) => {
    function getActions(userCompany: UserCompany, project: Project): MenuProps['items'] {
        const { isProjectAdmin, isProjectManager } = getProjectPermissions(userCompany, project.id);
        const items: MenuProps['items'] = [];

        if (isProjectManager || isProjectAdmin) {
            items.push({
                label: <Trans>Invite user</Trans>,
                key: `${project.id} UserManagement`,
                icon: <UserOutlined />,
                onClick: () => {
                    onManageUsers(project.id);
                }
            });
        }

        if (isProjectManager || isProjectAdmin) {
            items.push({
                label: <Trans>Edit</Trans>,
                key: `${project.id} EditProject`,
                icon: <EditOutlined />,
                onClick: () => {
                    onEditProject(project.id);
                }
            });
        }

        if (isProjectAdmin) {
            items.push({
                label: <Trans>Edit supplier config</Trans>,
                key: `${project.id} EditSupplierConfig`,
                icon: <SettingOutlined />,
                onClick: () => {
                    onEditSupplier(project.id);
                }
            });
        }

        if (isProjectManager || isProjectAdmin) {
            items.push({
                label: project.isArchived ? <Trans>Activate project</Trans> : <Trans>Archive project</Trans>,
                key: `${project.id} ArchiveProject`,
                icon: <InboxOutlined />,
                onClick: () => {
                    onArchiveProject(project);
                }
            });
        }

        return items;
    }

    return [
        {
            title: <Trans>Project Number</Trans>,
            dataIndex: 'number',
            key: 'number',
            sorter: true
        },
        {
            title: <Trans>Project Name</Trans>,
            dataIndex: 'name',
            key: 'name',
            sorter: true
        },
        {
            title: <Trans>Construction start</Trans>,
            dataIndex: 'startDate',
            key: 'startDate',
            sorter: true,
            render: (startDate: Date) => <DateFormatter date={startDate} />
        },
        {
            title: <Trans>Construction end</Trans>,
            dataIndex: 'endDate',
            key: 'endDate',
            sorter: true,
            render: (endDate: Date) => <DateFormatter date={endDate} />
        },
        {
            title: null,
            dataIndex: 'enterProject',
            render: (_: string, record: Project) => {
                const { isProjectAdmin, isProjectManager, isForeman, isConcreteEngineer } = getProjectPermissions(
                    userCompany,
                    record.id
                );

                if (!(!isProjectAdmin || (isProjectAdmin && (isForeman || isConcreteEngineer || isProjectManager)))) {
                    return null;
                }

                return (
                    <Button
                        className="projects-table__button"
                        data-testid={`enter-project-button-${record.id}`}
                        type="link"
                        size="small"
                        onClick={() => onEnterProject(record.id, record.marketId)}>
                        <ImportOutlined /> <Trans>Enter project</Trans>
                    </Button>
                );
            }
        },
        {
            title: null,
            dataIndex: 'actions',
            render: (_: string, record: Project) => {
                const items: MenuProps['items'] = getActions(userCompany, record);
                return (
                    <Dropdown menu={{ items }} trigger={['click']}>
                        <EllipsisOutlined className="projects-table__3dotIcon" />
                    </Dropdown>
                );
            }
        }
    ];
};
