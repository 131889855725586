import { t, Trans } from '@lingui/macro';
import { Form, Input } from 'antd';
import FormItem from 'antd/lib/form/FormItem';

export function CustomerIdFormItem() {
    const form = Form.useFormInstance();
    const selectedSupplierSoftware = Form.useWatch('supplierSoftware', form);

    return (
        <FormItem
            name="customerId"
            label={
                selectedSupplierSoftware == 'holcim.concrete.direct.v2' ? (
                    <Trans>client_id</Trans>
                ) : (
                    <Trans>Customer ID</Trans>
                )
            }
            rules={[
                {
                    required: true,
                    message: <Trans>Please input customer ID</Trans>
                }
            ]}>
            <Input type="text" data-testid="customerId" placeholder={t`Enter customer id`} />
        </FormItem>
    );
}
