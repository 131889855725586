import { Rule } from 'antd/lib/form';
import { BUSINESS_PAGES_VALUES } from '../businessPages';
import { MARKET_VALUES, MARKETS } from '../markets';
import MARKET_DATA_AUS from './marketDataAUS';
import MARKET_DATA_DE from './marketDataDE';
import MARKET_DATA_US from './marketDataUS';

export interface ValuesDropDown {
    value: number | string;
    label: string | JSX.Element;
}

export interface IMarketUnits {
    volume: string;
    water: string;
    density: string;
    temperature: string;
    compressiveStrength: string;
    slump: string;
    maxAggregateSize: string;
}

export interface IMarketFormats {
    dateTimeForm: string;
    dateForm: string;
    timeForm: string;
    dateTime: string;
    date: string;
    time: string;
}

export interface IMarketData {
    businessPages: BUSINESS_PAGES_VALUES[];
    custom?: {
        orderValidations?: {
            additionalRequirementRules?: Rule[];
            locationDescriptionRules?: Rule[];
            locationNameRules?: Rule[];
        };

        // depracated
        orderDependencies?: {
            loadSizes?: ValuesDropDown[];
            slumps?: ValuesDropDown[];
            spacingInMinutes?: ValuesDropDown[];
        };
    };

    constants?: {
        units: IMarketUnits;
        formats: IMarketFormats;
        slumps?: ValuesDropDown[];
        loadSizes?: ValuesDropDown[];
        spacingInMinutes?: ValuesDropDown[];
        methodConcrete?: ValuesDropDown[];
        methodConcreteUseChloride?: ValuesDropDown[];
        moisture?: ValuesDropDown[];
        strengthDevelopmentClass?: ValuesDropDown[];
        densityClass?: ValuesDropDown[];
        chlorideContent?: ValuesDropDown[];
        typeOfConcrete?: ValuesDropDown[];
        consistencyType?: ValuesDropDown[];
        exposureClassXc?: ValuesDropDown[];
        exposureClassXd?: ValuesDropDown[];
        exposureClassXf?: ValuesDropDown[];
        exposureClassXa?: ValuesDropDown[];
        exposureClassXm?: ValuesDropDown[];
        exposureClassXs?: ValuesDropDown[];
        airMeasurmenMethod?: ValuesDropDown[];
        testSpecimenTestType?: ValuesDropDown[];
        testSpecimenTestPurpose?: ValuesDropDown[];
    };
}

export const MARKETS_DATA: Record<MARKET_VALUES, IMarketData> = {
    [MARKETS.DE]: MARKET_DATA_DE,
    [MARKETS.US]: MARKET_DATA_US,
    [MARKETS.AUS]: MARKET_DATA_AUS
};

export function mapStringToDropdown(data: void | string[]) {
    if (data) {
        const dropdownValues = data
            .filter((x) => x != null)
            .map((x) => ({
                label: x,
                value: x
            }));

        return dropdownValues;
    }

    return null;
}
