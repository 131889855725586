// TODO: we should use supplier name instend of supplierSoftware
export const SUPPLIERS = {
    SIMMA: 'mbk.simma',
    SAUTER: 'dsa',
    CEMEX_GO: 'cemex.cemexgo',
    CONCRETE_DIRECT_V2: 'holcim.concrete.direct.v2',
    DYCKERHOFF: 'dyckerhoff',
    GODEL: 'godel.pahm',
    CIMIC: 'cimic',
    BERGER: 'berger',
    TRAPOBET: 'trapobet'
} as const;

export type SUPPLIER_VALUES = (typeof SUPPLIERS)[keyof typeof SUPPLIERS];
