import { Trans } from '@lingui/macro';
import { Radio } from 'antd';
import FormItem from 'antd/lib/form/FormItem';

export function TypeSusbcriptionFormItem() {
    return (
        <FormItem
            label={<Trans>Subscription</Trans>}
            name="typeSusbcription"
            rules={[
                {
                    required: true,
                    message: <Trans>This field is required</Trans>
                }
            ]}>
            <Radio.Group data-testid="typeSusbcription" style={{ justifyContent: 'flex-start', gap: '1rem' }}>
                <Radio data-testid="withSubscription" value="subscription">
                    <Trans>Yes</Trans>
                </Radio>
                <Radio data-testid="withoutSubscription" value="cronjob">
                    <Trans>No</Trans>
                </Radio>
            </Radio.Group>
        </FormItem>
    );
}
