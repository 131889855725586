/* eslint-disable react/jsx-props-no-spreading */
import { t } from '@lingui/macro';
import { Form } from 'antd';
import { FormItemInputProps } from 'antd/lib/form/FormItemInput';
import {
    CONCRETE_LOAD_LITE_MODAL_ACTION,
    ConcreteLoadLiteModalAction
} from 'src/app-react/business-page/shared/Modals/OrderLiteModal/models/Types';
import InputNumber from 'src/app-react/components/Form/InputNumber';
import numberRule from 'src/app-react/components/Form/Validations/numberRule';
import requiredRule from 'src/app-react/components/Form/Validations/requiredRule';
import useProjectConstantsState from 'src/app-react/hooks/useProjecsConstantsState';

interface OrderVolumeFormItemProps extends FormItemInputProps {
    action?: ConcreteLoadLiteModalAction;
}

function OrderVolumeFormItem({ action, ...props }: OrderVolumeFormItemProps) {
    const { units } = useProjectConstantsState();

    const isReadOnly = action === CONCRETE_LOAD_LITE_MODAL_ACTION.EDIT;

    return (
        <Form.Item
            label={t`Ordered quantity`}
            name="orderVolume"
            rules={[requiredRule, numberRule({ minOrEqual: 0 })]}
            {...props}>
            <InputNumber
                readOnly={isReadOnly}
                placeholder={t`Enter ordered quantity`}
                data-testid="orderVolume"
                addonAfter={units.volume}
            />
        </Form.Item>
    );
}

export default OrderVolumeFormItem;
